<template>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{button}}
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
                 <v-container>
                 <v-row>
                    <v-col>
                         <slot :onClose="onClose" ></slot>
                    </v-col>
                 </v-row>
                 </v-container>
            </v-card-text>
            
        </v-card>
      </v-dialog>
</template>

<script>

export default {
     name: "DialogCreateUser",
     props:{
       button:{
         type:String,
         default: 'commons.open'
       },
       title:{
         type:String,
         default: 'commons.title'
       }
     },
     data: () => ({
          dialog: false
     }),
     methods:{
       onClose(val) {
        this.dialog = val;
        console.log('dialog',val);
      }
     }
}
</script>